import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Privacy from '../components/Policy';

const PrivacyPage: React.FC = () => {
  return (
    <Layout page='privacy'>
      <SEO title="Privacy Policy" />
      <Privacy />
    </Layout>
  );
};

export default PrivacyPage;
